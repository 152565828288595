/* Harmonia Sans Std Light - latin */
@font-face {
    font-family: 'Harmonia Sans Std';
    font-style: normal;
    font-weight: 300;
    src: local(''),
         url('/assets/fonts/harmoniasansstd/HarmoniaSansStd-Light.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/harmoniasansstd/HarmoniaSansStd-Light.woff') format('woff'); /* Modern Browsers */
}

/* Harmonia Sans Std Regular - latin */
@font-face {
    font-family: 'Harmonia Sans Std';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('/assets/fonts/harmoniasansstd/HarmoniaSansStd-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/harmoniasansstd/HarmoniaSansStd-Regular.woff') format('woff'); /* Modern Browsers */
}

/* Harmonia Sans Std Italic - latin */
@font-face {
    font-family: 'Harmonia Sans Std';
    font-style: italic;
    font-weight: 400;
    src: local(''),
         url('/assets/fonts/harmoniasansstd/HarmoniaSansStd-Italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/harmoniasansstd/HarmoniaSansStd-Italic.woff') format('woff'); /* Modern Browsers */
}

/* Harmonia Sans Std SemiBd - latin */
@font-face {
    font-family: 'Harmonia Sans Std';
    font-style: normal;
    font-weight: 600;
    src: local(''),
         url('/assets/fonts/harmoniasansstd/HarmoniaSansStd-SemiBd.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/harmoniasansstd/HarmoniaSansStd-SemiBd.woff') format('woff'); /* Modern Browsers */
}

/* Harmonia Sans Std Bold - latin */
@font-face {
    font-family: 'Harmonia Sans Std';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url('/assets/fonts/harmoniasansstd/HarmoniaSansStd-Bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/harmoniasansstd/HarmoniaSansStd-Bold.woff') format('woff'); /* Modern Browsers */
}
