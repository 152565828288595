.section {
    &.section--margin-top {
        margin-top: 120px;
    }

    &.section--margin-bottom {
        margin-bottom: 120px;
    }

    &.section--padding-top {
        padding-top: 64px;

        @include media-breakpoint-up(lg) {
            padding-top: 120px;
        }
    }

    &.section--padding-bottom {
        padding-bottom: 64px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 120px;
        }
    }

    &.section--bg-light {
        background-color: $color__gray--light;

        .page__main > & {
            &:last-of-type {
                body:not([data-editmode='true']) & {
                    border-bottom: 1px solid $color__gray;
                }
            }
        }

        body[data-template='home'].banner-fullscreen .page__main > & {
            &:first-of-type {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: -80px;
                    left: 0;
                    width: 100%;
                    height: 80px;
                    background-color: $color__gray--light;
                    pointer-events: none;
                }
            }
        }
    }

    &.section--bg-dark {
        background-color: $color__primary;
        &.section--crumb {
            .meta-navigation--crumb .meta-navigation__item:not(:last-child)::after{
                filter: $filter__white;
            }
        }
        ul.list-styled li::before, .content--editor ul li::before, .m-p ul li::before{
            background-color: $color__white;
        }
        .button{
            border: 1px solid $color__white;
        }
        a.arrow::after{
            filter: $filter__white;
        }
        .content-navigation__body{
            a{
                color: $color__font!important;
            }
        }
        .dgf-list--list .card a{
            color: $color__white!important;
        }
        .dgf-list--news .card--news .card-header,
        .dgf-list.dgf-list--list .card-title a {
            color: $color__white !important;
        }
        .dgf-list--list .icon--fluid:not(.icon--highlight)>img, .icon--fluid:not(.icon--highlight)>svg{
            filter: $filter__white;
        }
        .dgf-list--list .card-text__info,
        .dgf-list--list .card-date__weekday{
            color: $color__white;
        }
    }

    &.section--bg-image {
        position: relative;
        z-index: 0;
    }

    &__background-image {
        position: absolute;
        z-index: -1;
        top: 0; left: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    body[data-template='home'] .page__main > & {
        header {
            margin-bottom: 48px;
        }
    }
}
