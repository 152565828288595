.off-canvas{
    &__toggle{
        &--open{
            @include hover-focus-visible{
                span{
                    &.text{
                        color: $color__primary;
                    }
                    &.lines{
                        @include transition();
                        background-color: $color__primary;
                        &:before,
                        &:after{
                            background-color: $color__primary;
                        }
                    }
                }
            }

        }
    }

}
body .off-canvas__trigger:checked+.off-canvas__wrapper{
    .off-canvas{
        &__toggle{
            &--open{

                    span{
                        &.text{
                            color: $color__primary;
                        }
                        &.lines{
                            @include transition();
                            &:before,
                            &:after{
                                background-color: $color__primary;
                            }
                        }
                    }
            }
        }

    }
}
